import { useState } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';

function Modal({submitModal, children, open, closeModal, controlsEnabled}) {
  return (
    <Transition show={open} className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          {ref =>
            <div ref={ref} className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
          }
        </Transition.Child>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          {ref =>
            <div ref={ref} className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-md sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              {children}
              {submitModal ? (
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                    <button disabled={!controlsEnabled} onClick={submitModal} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Submit
                    </button>
                  </span>
                  <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                    <button disabled={!controlsEnabled} onClick={closeModal} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Cancel
                    </button>
                  </span>
                </div>
              ) : (
                <div className="mt-5 sm:mt-6">
                  <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0">
                    <button disabled={!controlsEnabled} onClick={closeModal} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Close
                    </button>
                  </span>
                </div>
              )}
            </div>
          }
        </Transition.Child>
      </div>
    </Transition>
  )
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitModal: PropTypes.func,
  children: PropTypes.node.isRequired,
  controlsEnabled: PropTypes.bool
};

Modal.defaultProps = {
  controlsEnabled: true
}

export default Modal;
