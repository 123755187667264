import PropTypes from 'prop-types';
import Modal from '../components/modal';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';

function EventPopup({event, show, onClose}) {
  let format = "L LT";
  if (event.allDay) {
    format = "L";
  }
  return (
    <Modal open={show} closeModal={onClose}>
      <h3 className="text-lg leading-6 text-center font-medium text-gray-900" id="modal-headline">{event.title}</h3>
      <div className="mt-5 border-t border-gray-200 pt-5">
        <dl>
          <div className="sm:grid sm:grid-cols-6 sm:gap-4">
            <dt className="text-sm leading-5 font-medium text-gray-500">
              from
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-5">
              {moment(event.start).format(format)}
            </dd>
          </div>
          <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-6 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">
              to
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-5">
              {moment(event.end).format(format)}
            </dd>
          </div>
          {event.location &&
            <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-6 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <dt className="text-sm leading-5 font-medium text-gray-500">
                location
              </dt>
              <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-5">
                {event.location}
              </dd>
            </div>
          }
          {event.description &&
            <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-6 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <dt className="text-sm leading-5 font-medium text-gray-500">
                notes
              </dt>
              <dd
                className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-5"
                dangerouslySetInnerHTML={{__html: sanitizeHtml(event.description)}}>
              </dd>
            </div>
          }
        </dl>
      </div>
    </Modal>
  )
}

EventPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired
};

export default EventPopup;
