import PropTypes from 'prop-types';
import { useEffect, useState, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getFeed, getEvents } from '../common/api';
import Alert from '../components/alert';
import Loading from '../components/loading';
import ShareNavigator from '../components/share_navigator';
import EventPopup from './event_popup';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { last, pickBy, identity } from 'lodash';
import { parseIcalendar } from './events';
import {
  calendarBgColorStyle,
  calendarTextColorStyle
} from '../common/utils';
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment)

const defaultVisibleStart = (date, view) => {
  switch(view) {
    case "month":
      return moment(date).startOf('month').startOf('week');
    case "agenda":
      return moment(date).startOf('day');
    default:
      return moment(date).startOf(view);
  }
}

const defaultVisibleEnd = (date, view) => {
  switch(view) {
    case "month":
      return moment(date).endOf('month').endOf('week');
    case "agenda":
      return moment(date).add(1, 'month').endOf('day');
    default:
      return moment(date).endOf(view);
  }
}

function App({showNavigator}) {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const defaultView = searchParams.get('view') || "month";
  const defaultDate = searchParams.get('date') ? moment(searchParams.get('date')) : moment();

  const { uid } = useParams();
  const [ alert, setAlert ] = useState();
  const [ share, setShare ] = useState();
  const [ ical, setIcal ] = useState();
  const [ popupEvent, setPopupEvent ] = useState();
  const [ showPopup, setShowPopup ] = useState(false);
  const [ visibleRange, setVisibleRange ] = useState({
    start: defaultVisibleStart(defaultDate, defaultView),
    end: defaultVisibleEnd(defaultDate, defaultView)
  });
  const events = useMemo(() => (parseIcalendar(ical, visibleRange)), [ical, visibleRange]);

  useEffect(() => {
    console.log(`Need to retrieve ${uid} from the server`);
    let timer = null;
    getFeed(uid).then(({data, errors}) => {
      if (data) {
        setShare({
          name: data.name,
          id: data.shareId
        });
        setIcal(data.ical);
        timer = setInterval(async () => {
          const refreshedData = await getEvents(uid);
          setIcal(refreshedData);
        }, 60000);
      } else {
        console.log(errors);
      }
    }).catch(() => {
      setAlert({
        level: "error",
        message: "Could not find calendar. Please check the URL"
      });
    });
    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [uid]);

  const updateSearchParams = (newValue) => {
    const view = searchParams.get('view');
    const date = searchParams.get('date');
    const originalParams = pickBy({view, date}, identity);
    setSearchParams({...originalParams, ...newValue});
  };

  const renderUI = () => (
    <>
      {showNavigator && <ShareNavigator shareName={share.name} shareId={share.id} />}
      <div className="mt-5">
        <Calendar
          localizer={localizer}
          defaultView={defaultView}
          onView={(view) => updateSearchParams({view})}
          defaultDate={defaultDate.toDate()}
          scrollToTime={new Date(1970, 1, 1, 8)}
          onNavigate={(date) => updateSearchParams({date: moment(date).format("YYYY-MM-DD")})}
          events={events}
          style={{height: 750}}
          eventPropGetter={(event) => {
            return {style: {...calendarBgColorStyle(event.color), ...calendarTextColorStyle(event.color)}};
          }}
          onSelectEvent={(event) => {
            setPopupEvent(event);
            setShowPopup(true);
          }}
          onRangeChange={(dates) => {
            if (dates.start) {
              setVisibleRange({start: moment(dates.start), end: moment(dates.end).endOf('day')});
            } else {
              setVisibleRange({start: moment(dates[0]), end: moment(last(dates)).endOf('day')});
            }
          }}
          />
      </div>
      {popupEvent &&
        <EventPopup event={popupEvent} show={showPopup} onClose={() => setShowPopup(false)} />
      }
    </>
  )
  return (
    <>
      {alert &&
        <Alert level={alert.level}>
          {alert.message}
        </Alert>
      }
      {share ? renderUI() : <Loading />}
    </>
  )
}

App.propTypes = {
  showNavigator: PropTypes.bool
};

App.defaultProps = {
  showNavigator: false
};

export default App;
