import JsonApi from 'devour-client';
import { flatMap } from 'lodash';
import axios from 'axios';

const jsonApi = new JsonApi({apiUrl: window.location.origin});

jsonApi.define('share', {
  name: "",
  publicName: "",
  description: "",
  active: true,
  url: "",
  icalendarUrl: "",
  htmlUrl: "",
  embedUrl: "",
  writeBackId: "",
  calendars: {
    jsonApi: 'hasMany',
    type: 'calendars'
  }
});

jsonApi.define('calendar', {
  defaultFilter: "",
  summary: "",
  hideTransparent: false,
  addPrefix: false,
  eventPrefix: "",
  color: "",
  sourceableType: "",
  sourceName: "",
  writable: false,
  share: {
    jsonApi: 'hasOne',
    type: 'shares'
  },
  filters: {
    jsonApi: 'hasMany',
    type: 'filters'
  }
});

jsonApi.define('filter', {
  matcher: "",
  type: "",
  priority: 0,
  calendar: {
    jsonApi: 'hasOne',
    type: 'calendars'
  }
});

jsonApi.define('feed', {
  ical: "",
  shareId: "",
  name: "",
  publicName: ""
});

const include = "calendars,calendars.filters";

const findShare = (id) => {
  return jsonApi.find('share', id, {include});
};

const updateShare = async (id, attrs, calendars) => {
  jsonApi.headers["X-CSRF-Token"] = document.getElementsByName("csrf-token")[0].content;
  await Promise.all(flatMap(calendars, (calendarAttrs, calendarId) => ([
    ...calendarAttrs.filtersToDelete.map(filter => deleteFilter(filter, calendarId)),
    ...calendarAttrs.filters.map(filter => upsertFilter(filter, calendarId)),
    updateCalendar(calendarId, calendarAttrs)
  ])));
  return jsonApi.update('share', {
    id,
    ...attrs
  }, {
    include
  });
};

const deleteFilter = ({id}, calendarId) => {
  return jsonApi.one('calendar', calendarId).one('filter', id).destroy();
}

const upsertFilter = (filter, calendarId) => {
  const urlBuilder = jsonApi.one('calendar', calendarId);
  if (filter.id) {
    return urlBuilder.one('filter', filter.id).patch(filter);
  } else {
    return urlBuilder.all('filter').post(filter);
  }
};

const updateCalendar = (id, attrs) => {
  return jsonApi.update('calendar', {
    id,
    ...attrs
  });
};

const getFeed = (uid) => (jsonApi.find('feed', uid));

const getEvents = async (uid) => {
  const { data } = await axios.get(`/feeds/${uid}.ics`);
  return data;
};

export {
  findShare,
  updateShare,
  getFeed,
  getEvents
}
