import ical from 'ical.js';
import jstz from 'jstz';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
const timezone = jstz.determine();
const tzid = timezone.name();

const parseIcalendar = (data, visibleRange) => {
  if (data === undefined) {
    return [];
  }
  const parsed = new ical.Component(ical.parse(data));
  const events = parsed.getAllSubcomponents('vevent');
  for (let tz of parsed.getAllSubcomponents('vtimezone')) {
    ical.TimezoneService.register(tz);
  }
  return processEvents(events, {...visibleRange, range: moment().range(visibleRange.start, visibleRange.end)});
};

const processEvents = (events, visibleRange) => {
  const exceptions = {};
  const recurring = [];
  const processedEvents = [];
  for (let event of events) {
    event = new ical.Event(event);
    if (event.isRecurring()) {
      recurring.push(event.component);
    } else if (event.isRecurrenceException()) {
      exceptions[event.uid] ??= [];
      exceptions[event.uid].push(event);
    } else {
      processEvent(event, event.startDate, event.endDate, visibleRange, processedEvents);
    }
  }
  for (const component of recurring) {
    event = new ical.Event(component, {exceptions: exceptions[component.getFirstPropertyValue('uid')]});
    const i = event.iterator();
    let next = i.next();
    while (next) {
      let occurrence = event.getOccurrenceDetails(next);
      if (moment(occurrence.endDate.toString()) < visibleRange.start) {
        next = i.next();
        continue
      }
      if (moment(occurrence.startDate.toString()) > visibleRange.end) {
        break
      }
      let eventInstance = occurrence.item;
      processEvent(eventInstance, occurrence.startDate, occurrence.endDate, visibleRange, processedEvents);
      next = i.next();
    }
  }
  return processedEvents;
};

const processEvent = (event, start, end, visibleRange, processedEvents) => {
  let eventStart = convertZone(start);
  let eventEnd = convertZone(end);
  if (eventStart === null || eventEnd === null) {
    return
  }
  const allDay = eventStart.isDate && eventEnd.isDate;
  eventStart = moment(eventStart.toString());
  eventEnd = moment(eventEnd.toString());
  if (eventStart.within(visibleRange.range) || eventEnd.within(visibleRange.range) || (eventStart <= visibleRange.start && eventEnd >= visibleRange.end)) {
    processedEvents.push({
      title: event.summary,
      allDay,
      start: eventStart.toDate(),
      end: eventEnd.toDate(),
      color: event.component.getFirstPropertyValue('color'),
      description: event.description,
      location: event.location
    });
  }
};

const convertZone = (date) => {
  if (date === null || date.isDate) {
    return date;
  }
  const utc = ical.Timezone.utcTimezone;
  return ical.Time.fromString(moment.utc(date.convertToZone(utc).toString()).tz(tzid).format('YYYY-MM-DD[T]HH:mm:ss'));
}

export {
  parseIcalendar,
  tzid
}
